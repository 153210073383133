import React from "react"
import { Box } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper"
import "swiper/swiper.min.css"
import "swiper/css/pagination"
import "swiper/css/autoplay"

import CdcCardBanner from "./CdcCardBanner"
import VenoStategyBanner from "./VenoStategyBanner"
import PoolBoostBanner from "./PoolBoostBanner"

const PromotionBanner = () => {
  const banners = [
    <SwiperSlide key="card">
      <CdcCardBanner />
    </SwiperSlide>,
    <SwiperSlide key="veno">
      <VenoStategyBanner />
    </SwiperSlide>,
    <SwiperSlide key="boost">
      <PoolBoostBanner />
    </SwiperSlide>,
  ]

  if (banners.length <= 0) return null

  return (
    <Box>
      <Swiper
        modules={[Autoplay, Pagination]}
        loop={banners.length >= 2}
        autoplay={banners.length >= 2 ? { delay: 5000 } : undefined}
        pagination={{ clickable: true }}
      >
        {banners}
      </Swiper>
    </Box>
  )
}

export default PromotionBanner

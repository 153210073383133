import "./Banner.scss"
import React from "react"
import { Box, styled, useMediaQuery, useTheme } from "@mui/material"
import cardImage from "../../assets/cdc-card.webp"
import Text from "../Text"
import Button from "../Button"

const BgContainer = styled("div")(() => {
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  return {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundImage: `url(${cardImage})`,
    height: "100%",
    width: "100%",
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat",
    backgroundSize: isLgUp ? "auto 100%" : "auto 50%",
    opacity: isLgUp ? 0.5 : 0.25,
  }
})

const CdcCardBanner = () => {
  return (
    <Box className="banner-container cdc">
      <BgContainer />
      <Box zIndex={1}>
        <h3>Crypto to Cash.</h3>
        <h3>Seamlessly</h3>
        <Box mt="12px" mb="12px">
          <Text>
            Link your Crypto.com card to your wallet and use your funds anywhere
            you shop.
          </Text>
        </Box>
        <Box display="flex" gap="24px">
          <a
            href="https://cronos.org/card"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", zIndex: 2 }}
          >
            <Button sx={{ boxSizing: "border-box" }} size="small">
              Learn More
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default CdcCardBanner
